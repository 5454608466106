import React from "react";
import { object } from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";

import { Layout } from "components/Layout";

const ContactPage = ({ location, data }) => (
  <Layout location={location} title={data.mdx.frontmatter.pageTitle}>
    <MDXRenderer>{data.mdx.body}</MDXRenderer>
  </Layout>
);

export const query = graphql`
  query {
    mdx(slug: { eq: "contact" }) {
      id
      body
      frontmatter {
        pageTitle
      }
    }
  }
`;

ContactPage.propTypes = {
  location: object.isRequired,
  data: object.isRequired,
};

export default ContactPage;
